import { useMemo } from "react";
import SupportFormAPI from "api/SupportFormAPI";
import useFormData from "hooks/useFormData";
import useSupportFormsOptions from "hooks/SupportForm/useSupportFormsOptions";
import { useAuthContext } from "context/AuthContext";
import Toast from "utils/Toast";

import Box from "components/general/Box";
import Button from "components/buttons/Button";
import FormDropdown from "components/form/FormDropdown";
import FormInput from "components/form/FormInput";
import Table from "components/general/Table";

export default function SpecialistWorkBox() {

   const { support_forms_assignments, updateItemsData } = useAuthContext();
   const { data, onDataChange, clearData } = useFormData();

   const addSupportForm = async() => {

      if ( !support_forms_assignments ) return;

      const form_id = Number( data.support_form_id );
      const was_added = !!support_forms_assignments.find( item => item.support_form.id === form_id );

      if ( was_added ) {
         Toast.error( "Ta forma wsparcia została już przypisana do specjalisty!" )
         return;
      }

      clearData();
      await SupportFormAPI.addMe( form_id, {
         time_frame_minutes: Number( data.time_frame_minutes ),
         place: String( data.place )
      })
      
      Toast.success( "Forma wsparcia została pomyślnie dodana!" );
      updateItemsData();
   }


   const deleteSupportForm = async( id: number ) => {
      await SupportFormAPI.removeMe( id );
      Toast.success( "Forma wsparcia została pomyślnie usunięta!" );
      updateItemsData();
   }


   const rows = useMemo(() => {

      if ( !support_forms_assignments ) return null;

      return support_forms_assignments.map( item => ({
         id: item.support_form.id,
         cells: [
            { content: item.support_form.name },
            { content: item.time_frame_minutes },
            { content: item.place },
            { actions: [
               { svg: "delete", onClick: () => deleteSupportForm( item.support_form.id )}
            ]}
         ]
      }))
   }, [ support_forms_assignments ])


   return (
      <Box heading="Konteksty pracy">
         <p> Dodaj informacje o kontekstach pracy w Systemie zgodnych z formami wsparcia realizowanego przez Specjalistę w ramach projektu </p>

         <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: "0 20px" }}>

            <FormDropdown
               name="support_form_id"
               value={ data.support_form_id }
               label="Forma wsparcia"
               optionsHook={ useSupportFormsOptions }
               style={{ flex: "auto" }}
               onChange={ onDataChange }
            />

            <FormInput
               name="time_frame_minutes"
               value={ String( data.time_frame_minutes || "" )}
               label="Czas trwania aktywności w ramach formy wsparcia [min]"
               type="number"
               onChange={ onDataChange }
            />

            <FormInput
               name="place"
               label="Miejsce spotkań"
               value={ String( data.place || "" )}
               onChange={ onDataChange }
            />

            <Button
               disabled={ !data.support_form_id || !data.time_frame_minutes || !data.place || Number( data.time_frame_minutes ) <= 0 }
               onClick={ addSupportForm }
            > Dodaj FORMĘ WSPARCIA </Button>
         </div>


         <Table
            columns={[
               { label: "Możliwe formy wsparcia" },
               { label: "Czas trwania aktywności w ramach formy wsparcia [min]" },
               { label: "Miejsce spotkań" },
               { label: "Akcje" }
            ]}

            rows={ rows }
         />
      </Box>
   )
}