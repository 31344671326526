import { Link } from "react-router-dom";
import getImgFileUrl from "utils/getImgFileUrl";
import useSupportForms from "hooks/SupportForm/useSupportForms";
import { useAuthContext } from "context/AuthContext";
import Paths from "router/Paths";

import PageHeader from "components/general/PageHeader";
import Loader from "components/general/Loader";

import "./support-forms.scss";

export default function SupportForms() {

   const { participant } = useAuthContext();
   const { d } = useSupportForms({ limit: 100, participant_id: participant?.id });
   const support_forms = d?.data;

   return (
      <>
         <PageHeader
            title="Formy wsparcia"
            breadcrumbs={[ "Formy wsparcia" ]}
         />

         { !support_forms && <Loader/> }

         { support_forms && 
            <div className="support-forms">
               { support_forms.map( item => (
                  <Link key={ item.id } to={ Paths.SupportForm.Specialists( item.id )}>
                     { item.icon_file_id && <img src={ getImgFileUrl( item.icon_file_id, 150, 150 )}/>}
                     <span> { item.name } </span>
                  </Link>
               ))}
            </div>
         }

         { Array.isArray(support_forms) && support_forms?.length <= 0 && <div className="nothing">Nie masz przypisanych żadnych form wsparcia</div> }
      </>
   )
}