import { useState, useMemo } from "react";
import { cutText } from "utils/functions";
import Paths from "router/Paths";
import { useAuthContext } from "context/AuthContext";
import Box from "components/general/Box";
import FormInput from "components/form/FormInput";
import UserAvatar from "components/general/UserAvatar";
import ErrorMessage from "components/general/ErrorMessage";
import Button from "components/buttons/Button";
import SupportFormAPI from "api/SupportFormAPI";
import Toast from "utils/Toast";
import axios, {AxiosError} from 'axios';
import { DeleteSvg } from "svg/actions";
//import WithdrawModal from "components/modal/WithdrawModal";
import EnrollModal from "components/modal/EnrollModal";

import "./specialists-box.scss";

export default function SpecialistsBox({ support_form_id, support_form, items, update }: {
   support_form_id: number;
   support_form: SupportForm;
   items: SupportFormSpecialist[];
   update: any;
}) {

   const [ search, setSearch ] = useState("");

   const { participant } = useAuthContext();

   //const [ withdrawModalShown, setWithdrawModalShown ] = useState(false);
   const [ enrollModalShown, setEnrollModalShown ] = useState(false);

   let is_enrolled = false;
   if (support_form.participants?.length)
   {
      support_form.participants.map(participant_on_form => {
         if (participant_on_form.id == participant?.id && participant_on_form?.enrolled_by)
         {
            is_enrolled = true
         }
      })
   }

   const specialists = useMemo(() => {
      if ( !search ) return items;

      return items.filter( item => {
         const reg = new RegExp( search, "gi" ); 
         return reg.test( item.user.full_name );
      })
   }, [ items, search ])

   const withdraw = async (form_id: number) => {
      try {
         await SupportFormAPI.withdrawParticipant( form_id, participant?.id || 0 );
         Toast.success( "Użytkownik został usunięty z tej formy wsparcia" );
         update();
     } catch (error: any) {
         Toast.error( (axios.isAxiosError(error) && error.response?.data?.message)
             ? error.response.data.message
             : "Nieprawidłowe dane uwierzytelniające!")
     }
   }

   const enroll = async (form_id: number) => {
      try {
         await SupportFormAPI.enrollParticipant( form_id, participant?.id || 0 );
         Toast.success( "Użytkownik został aktywowany w tej formie wsparci" );
         update();
     } catch (error: any) {
         Toast.error( (axios.isAxiosError(error) && error.response?.data?.message)
             ? error.response.data.message
             : "Nieprawidłowe dane uwierzytelniające!")
     }
   }

   const onClose = () => {
      setEnrollModalShown(false);
   }

   const onConfirm = () => {
      //withdraw(support_form.id)
      enroll(support_form.id)
      setEnrollModalShown(false);
   }

   return (
      <Box className="specialists-box">

         {/*withdrawModalShown && <WithdrawModal
               text="...adjust if will be needed"
               onClose={ onClose }
               onConfirm={onConfirm}
           />*/}

         {enrollModalShown && <EnrollModal
               text={"Skorzystanie z tej formy wsparcia pobierze z Twojego konta " + support_form.cost + " punktów. Czy na pewno chcesz z niej skorzystać?"}
               onClose={onClose}
               onConfirm={onConfirm}
           />}

         <div className="specialists-box-search">
            <FormInput
               name="s"
               value={ search }
               label="Wyszukiwarka słowna"
               placeholder="Wpisz imię, nazwisko, słowa kluczowe..."
               onChange={( n, v ) => setSearch( String( v ))}
            />
         </div>

         {/*!!is_enrolled && <Button
            onClick={() => setWithdrawModalShown(true)}
            extra_classes={"outline"}
         ><div><DeleteSvg/> <span>Dezaktywuj</span></div></Button>*/}

         {!!participant && !!support_form && !is_enrolled && <Button
            type="submit"
            //extra_classes={"green"}
            onClick={() => setEnrollModalShown(true)}
         >Skorzystaj z formy wsparcia</Button>}

         <div className="specialists-box-items">
            { specialists.map( item => (
               <div key={ item.user.id }>
                  <UserAvatar user_id={ item.user.id } avatar_id={ item.user.avatar_file_id }/>

                  <h4> { item.user.full_name } </h4>

                  <p> { cutText( item.user.description || "", 140 )} </p>

                  <div>
                     <Button 
                        extra_classes="transparent"
                        to={ Paths.SupportForm.SpecialistView( support_form_id, item.user.id )}   
                     > Informacje </Button>
                     
                     <Button
                        extra_classes={is_enrolled ? '' : 'button-disabled'}
                        to={ Paths.SupportForm.MakeReservation( support_form_id, item.user.id )}
                     > Zapisz się </Button>

                  </div>
               </div>
            ))}
         </div>

         { !specialists.length && <ErrorMessage message="Brak treści do wyświetlenia."/>}
      </Box>
   )
}