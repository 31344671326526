import { useEffect } from "react";
import PollAPI from "api/PollAPI";
import useFormData from "hooks/useFormData";
import usePollsUnfilled from "hooks/Poll/usePollsUnfilled";
import validateFields from "utils/validateFields";

import Modal from "components/modal/Modal";
import FormRadioGroup from "components/form/FormRadioGroup";
import FormInput from "components/form/FormInput";
import Button from "components/buttons/Button";
import Toast from "utils/Toast";

import { questions, last_question } from "./questions";

export default function PollModal({ onClose }: {
   onClose: () => void;
}) {

   const { d: polls } = usePollsUnfilled();
   const { data, errors, onDataChange, setErrors } = useFormData();

   const filterQuestion = question => question.replace('%sfn%', '„' + data.support_form_name + '”');

   const onConfirm = async() => {
      const id = Number( data.poll_id );
      const d = {...data };
      delete d.poll_id;
      delete d.support_form_name;

      await PollAPI.fill( id, JSON.stringify( d ));
      onClose();

      Toast.success( `Ankieta dotycząca formy wsparcia ${ data.support_form_name } została wypełniona pomyślnie!` );
   }

   const validation = () => {
      const check = validateFields([
         { name: "answer_1" },
         { name: "answer_2" },
         { name: "answer_3" },
         { name: "answer_4" },
      ], data );

      setErrors( check.errors );
      return check.is_valid;
   }

   useEffect(() => {
      if ( !polls ) return;
      polls.length === 1 && onDataChange({ 
         poll_id: polls[0].id,
         support_form_name: polls[0].support_form.name 
      })
   }, [ polls ])

   return (
      <Modal
         title={ data.poll_id ? `Ankieta` : "Ankiety" }
         confirm_label="Zapisz"
         loading={ !polls }
         validationFn={ validation }
         onClose={ onClose }
         onConfirm={ data.poll_id ? onConfirm : undefined }
      >
         <>
         
            { !data.poll_id && polls &&
               <>
                  <p style={{ marginTop: 0, textAlign: "center" }}> 
                     { polls?.length 
                        ? `Wybierz formę wsparcia, dla której chcesz wypełnić ankietę:`
                        : `Aktualnie nie posiadasz żadnych ankiet do wypełnienia`
                     } 
                  </p>
               
                  { !!polls.length && polls.map( item => (
                     <Button 
                        key={ item.id }
                        extra_classes="outline"
                        style={{ width: "100%", marginBottom: 10 }}
                        onClick={() => onDataChange({
                           poll_id: item.id,
                           support_form_name: item.support_form.name
                        })}>
                        { item.support_form.name }
                     </Button>
                  ))}
               </>
            }

            { data.poll_id && 
               <>
                  <div style={{ marginBottom: "20px" }}> 
                     Forma wsparcia: <strong> { data.support_form_name as string } </strong>
                  </div>

                  { questions.map(( question, key ) => {

                     const name = "answer_" + ( key + 1 );
                     const comment_name = name + "_comment";

                     return (
                        <div key={ key } style={{ marginBottom: 20, borderBottom: "1px solid #b4b4b4" }}>
                           <FormRadioGroup
                              name={ name }
                              value={ Number( data[ name ])}
                              label={ filterQuestion( question )}
                              error={ errors[ name ]}
                              buttons={[
                                 { value: 1, label: "Bardzo nisko" },
                                 { value: 2, label: "Nisko" },
                                 { value: 3, label: "Przeciętnie" },
                                 { value: 4, label: "Wysoko" },
                                 { value: 5, label: "Bardzo wysoko" }
                              ]}
                              onChange={( n, v ) => onDataChange({
                                 [ n ]: v,
                                 [ comment_name ] : undefined
                              })}
                           />
   
                           {[ 1, 2 ].includes( Number( data[ name ])) && 
                              <FormInput
                                 name={ comment_name }
                                 value={ String( data[ comment_name ] || "" )}
                                 label="Prosimy o komentarz"
                                 onChange={ onDataChange }
                              />
                           }
                        </div>
                     )
                  })}

                  <FormInput
                     name="answer_5"
                     label={ filterQuestion( last_question )}
                     type="textarea"
                     value={ String( data.answer_5 || "" )}
                     style={{ margin: 0 }}
                     onChange={ onDataChange }
                  />
               </>
            } 
         </>
      </Modal>
   )
}