import Modal from "components/modal/Modal";
import "./enroll-modal.scss";

export default function EnrollModal({ text, children, ...props }: {
    text?: string;
    children?: React.ReactNode;
    onClose: () => void;
	onConfirm: () => void | Promise<void>;
}) {
    return (
        <Modal
           className="enroll-modal"
            title="Skorzystaj z formy wsparcia"    
            confirm_label="Skorzystaj z formy wsparcia" 
            {...props }
        >
            <>
                { text || "Czy na pewno chcesz z niej skorzystać?" }

                { children }
            </>
        </Modal>
    )
}